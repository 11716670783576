import BasePlugin from '../BasePlugin'

export default class RefreshFundingLimitDetailQueryChart extends BasePlugin {
  async execute (event, chartContext, config) {
    let category = config.w.config.xaxis.categories[config.dataPointIndex]

    let requestSectionArray = category.match(/\((.*?)\)/)
    let requestSection = null
    if (requestSectionArray) {
      requestSection = requestSectionArray[1]
    }
    /* if (isNaN(parseInt(requestSection))) {
      console.warn(`bad request section = ${requestSection}`)
      return false
    } */
    this.context.$set(this.context.getModel(), 'requestSection', parseInt(requestSection))
  }
}
